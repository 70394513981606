<style scoped></style>

<template>
  <fm-modal :mask-closable="true" :value="open" width="80%" @cancel="$emit('input', false)">
    <template v-if="open">
      <fm-title title-text="信息变更申请记录"></fm-title>
      <fm-table-new
        ref="apply-log"
        :simple-filter="true"
        :columns="applyLogColumns"
        border="row"
        size="small"
        :toolbox="showToolBox"
        :data-list="applyDataLog"
        :stripe="false"
        emptyPlaceholder="-">
        <div slot="empty">
          <img src="/static/images/null.png" style="width: 100px;"/>
          <div>暂无数据</div>
        </div>
      </fm-table-new>
    </template>
  </fm-modal>
</template>

<script>
import UpdateDetails from './details.vue'
import {dataAppleRequest} from '@/api'
import columns from './columns.js'
export default {
  props: {
    value: { type: Boolean, default: false },
    type: { type: String, default: null },
    showToolBox: {
      type: Array, default () {
        return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
      }
    },
    dataColumns: { type: Array, default: null }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      open: this.value,
      applyDataLog: []
    }
  },
  watch: {
    value (v) {
      if (v !== this.open) {
        this.open = v
      }
    },
    open: {
      immediate: true,
      handler (v) {
        if (v) {
          this.loadData()
          this.$nextTick(() => {
            this.$tableColumnUpdate(this.$refs['apply-log'], 'apply-log')
          })
        }
      }
    }
  },
  computed: {
    baseApplyLogColumns () {
      let data = [
        {
          title: '提交账号',
          field: 'applyUserName',
          sort: true
        },
        {
          title: '姓名',
          field: 'workerName',
          sort: true
        },
        {
          title: '身份证',
          field: 'workerIdNo',
          sort: true
        },
        {
          title: '类型',
          field: 'dataType',
          render (h, rowData) {
            return h('div', (columns.find(v => v.key === rowData.dataType) || {name: '其他'}).name)
          }
        },
        {
          title: '申请人',
          field: 'applyUserName',
          sort: true
        },
        {
          title: '类型',
          field: 'type',
          sort: true,
          render (h, rowData) {
            switch (rowData.type) {
              case 'add':
                return h('div', '添加')
              case 'edit':
                return h('div', '修改')
              case 'del':
                return h('div', '删除')
              default:
                return h('div', '其他')
            }
          }
        },
        {
          title: '申请时间',
          field: 'createTime',
          sort: true,
          width: 160,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.createTime ? rowData.createTime : '-')
          }
        },
        {
          title: '变更内容',
          field: 'details',
          sort: true,
          type: 'expand',
          render (h, row) {
            return h('div', {
              style: {
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center'
              }
            }, (row.details && row.details.length) ? [
              h('div', '共修改' + row.details.length + '条'),
              h('i', {'class': 'fmico fmico-top-arrow fm-table-cell-expand', style: {color: '#657180'}})
            ] : '-')
          },
          expand (h, row) {
            return h(UpdateDetails, {
              props: {
                content: row.details
              }
            })
          }
        },
        {
          title: '审批人',
          field: 'approveUserName',
          sort: true
        },
        {
          title: '审批状态',
          field: 'status',
          sort: true,
          render: (h, rowData) => {
            switch (rowData.status) {
              case 'wait':
                return h('div', '待审批')
              case 'pass':
                return h('div', '通过')
              case 'down':
                return h('div', '驳回')
              default:
                return h('div', '其他')
            }
          }
        }
      ]
      return data
    },
    applyLogColumns () {
      return this.$getTableConfig('apply-log', this.baseApplyLogColumns)
    }
  },
  methods: {
    async loadData () {
      let data = await dataAppleRequest.getMy({dataType: this.type})
      data.forEach((v) => {
        try {
          let hisDataInfo = v.hisDataInfo || {}
          let newDataInfo = v.newDataInfo || {}
          let currentData = v.currentData || {}
          v.workerName = ''
          if (v.dataType === 'worker') {
            v.workerName = currentData.name || hisDataInfo.name || newDataInfo.name
          } else {
            v.workerName = currentData.workerName || hisDataInfo.workerName || newDataInfo.workerName
          }
          v.workerIdNo = currentData.idNo || v.hisDataInfo.idNo || newDataInfo.idNo
          let hisData = v.hisData ? JSON.parse(v.hisData) : {}
          let newData = v.newData ? JSON.parse(v.newData) : {}
          let sourceKey = Object.keys(hisData)
          let fields = [...sourceKey, ...Object.keys(newData).filter(k => !sourceKey.includes(k))]
          let type = v.type
          let column = (columns.find(c => v.dataType === c.key) || {columns: []}).columns
          v.details = (this.dataColumns || column).filter(column => !fields.length || fields.includes(column.field)).map((column) => {
            return {
              title: column.title,
              current: !v.currentData ? '' : (column.text ? column.text(v.currentData) : v.currentData[column.field]),
              oldText: !v.hisDataInfo ? '' : (column.text ? column.text(v.hisDataInfo) : v.hisDataInfo[column.field]),
              updateText: type === 'del' ? '删除' : (v.newDataInfo && v.newDataInfo[column.field] ? (column.text ? column.text(v.newDataInfo) : v.newDataInfo[column.field]) : '')
            }
          })
        } catch (error) {
          console.log(error, 'error')
          v.details = []
        }
      })
      this.applyDataLog = data
    }
  }
}
</script>
