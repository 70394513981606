export default {
  path: '/performance',
  name: 'performance',
  component: () => import('@/views/performance'),
  children: [
    {
      path: 'wage_level',
      name: 'performance.wage_level',
      component: () => import('@/views/performance/wageLevel')
    },
    {
      path: 'worker',
      name: 'performance.worker',
      component: () => import('@/views/performance/worker')
    },
    {
      path: 'worker_subsidy',
      name: 'performance.worker_subsidy',
      component: () => import('@/views/performance/workerSubsidy')
    },
    {
      path: 'worker_wage',
      name: 'performance.worker_wage',
      component: () => import('@/views/performance/workerWage')
    },
    {
      path: 'wage_job_title',
      name: 'performance.wage_job_title',
      component: () => import('@/views/performance/wageJobTitle')
    },
    {
      path: 'worker_wage_job_title',
      name: 'performance.worker_wage_job_title',
      component: () => import('@/views/performance/workerWageJobTitle')
    },
    {
      path: 'worker_wage_level',
      name: 'performance.worker_wage_level',
      component: () => import('@/views/performance/workerWageLevel')
    },
    {
      path: 'worker_month',
      name: 'performance.worker_month',
      component: () => import('@/views/performance/workerMonth')
    }
  ]
}
