import tongchengConfig from './config/tongcheng'

export const envs = {
  dev: {
    env: 'hrms',
    webPort: '',
    hostName: 'dev.e-forward.cn',
    apiAdr: 'https://api.dev.e-forward.cn',
    remark: 'hrms',
    ipc: '皖ICP备2021009694号'
  },
  demo: {
    env: 'hrms_demo',
    webPort: '',
    hostName: 'demo.e-forward.cn',
    apiAdr: 'https://api.demo.e-forward.cn',
    remark: 'hrms_demo',
    ipc: '皖ICP备2021009694号'
  },
  nk: {
    env: 'hrms_nk',
    webPort: '',
    hostName: 'neikong.e-forward.cn',
    apiAdr: 'https://api.neikong.e-forward.cn',
    remark: 'hrms_nk',
    ipc: '皖ICP备2021009694号'
  },
  test: {
    env: 'hrms_test',
    webPort: '',
    hostName: 'test.e-forward.cn',
    apiAdr: 'https://api.test.e-forward.cn',
    remark: 'hrms_test',
    ipc: '皖ICP备2021009694号'
  },
  pro: {
    env: 'hrms_pro',
    webPort: '',
    hostName: 'pro.e-forward.cn',
    apiAdr: 'https://api.pro.e-forward.cn',
    remark: 'hrms_pro',
    ipc: '皖ICP备2021009694号'
  },
  tongcheng: {
    env: 'hrms_tongcheng',
    webPort: '',
    config: tongchengConfig,
    hostName: 'tongcheng.e-forward.cn',
    apiAdr: 'https://api.tongcheng.e-forward.cn',
    remark: 'hrms_tongcheng',
    ipc: '皖ICP备2021009694号'
  },
  tongchengpro: {
    env: 'hrms_tongcheng_pro',
    webPort: '',
    config: tongchengConfig,
    hostName: '192.168.0.252',
    apiAdr: 'http://192.168.0.252:8780',
    remark: 'hrms_tongcheng'
  },
  tongchengproWw: {
    env: 'hrms_tongcheng_pro_ww',
    webPort: '',
    config: tongchengConfig,
    hostName: '112.53.233.75',
    apiAdr: 'http://112.53.233.75:8780',
    remark: 'hrms_tongcheng_pro_ww'
  },
  taihu: {
    env: 'hrms_tongcheng_taihu',
    webPort: '',
    hostName: 'thfy.e-forward.cn',
    apiAdr: 'https://api.thfy.e-forward.cn',
    remark: 'hrms_tongcheng_taihu',
    ipc: '皖ICP备2021009694号'
  },
  taihuZyy: {
    env: 'hrms_taihuzyy',
    webPort: '',
    hostName: '172.16.109.56',
    apiAdr: 'http://172.16.109.56:8780',
    remark: 'hrms_taihuzyy',
    defaultTheme: 5,
    themes: [5],
    sysThemes: ['yellow'],
    defaultSysTheme: 'yellow',
    cmpName: '太湖县中医院智能人事管理平台'
  },
  susong: {
    env: 'hrms_susong',
    webPort: '',
    hostName: 'susong.e-forward.cn',
    apiAdr: 'https://api.susong.e-forward.cn',
    remark: 'hrms_susong',
    ipc: '皖ICP备2021009694号'
  },
  susongPro: {
    env: 'susongPro',
    webPort: '',
    hostName: '192.168.10.253',
    apiAdr: 'http://192.168.10.253:8780',
    remark: 'susongPro'
  },
  yuexipro: {
    env: 'hrms_yuexi_pro',
    webPort: '',
    hostName: '112.30.157.41',
    apiAdr: 'http://112.30.157.41:8780',
    remark: 'hrms_yuexi_pro'
  },
  huainingpro: {
    env: 'huainingpro',
    webPort: '',
    hostName: 'ahhnfy.e-forward.cn',
    apiAdr: 'https://api.ahhnfy.e-forward.cn',
    remark: 'huainingpro',
    ipc: '皖ICP备2021009694号'
  },
  test1: {
    env: 'test_hrms',
    webPort: '8080',
    hostName: 'localhost',
    // apiAdr: 'http://112.30.157.41:8780',
    apiAdr: 'https://api.dev.e-forward.cn',
    // apiAdr: 'http://127.0.0.1:8780',
    remark: 'test_hrms',
    ipc: '皖ICP备2021009694号'
  },
  test2: {
    env: 'hrms_demo',
    webPort: '8081',
    hostName: 'localhost',
    apiAdr: 'https://api.dev.e-forward.cn',
    remark: 'hrms_demo',
    ipc: '皖ICP备2021009694号'
  }
}
  
const initEnv = () => {
  // 根据前端接口来判断环境
  let env = null
  let hostName = window.location.hostname
  let useEnvs = Object.keys(envs).filter(key => envs[key].hostName === hostName)
  console.log(useEnvs, 'useEnvs')
  let webPort = window.location.port
  if (useEnvs.length > 0) {
    env = envs[useEnvs[0]]
    if (useEnvs.length > 1) {
      let useEnvKey = useEnvs.find(key => envs[key].webPort === webPort)
      console.log(useEnvKey)
      if (useEnvKey) {
        env = envs[useEnvKey]
      }
    }
  }
  console.log(env ? ('环境:' + env.remark) : '获取环境失败, 使用' + envs['dev'].remark)
  return env || envs['test1']
}

export default initEnv()
