export default {
  selectDatas: {
    workerJobTypes: ['全额编人员', '差额编人员', '自收自支编人员', '社会化人员', '非在编人员'],
    jobTitleTypes: [{key: '管理岗位', label: '管理岗位'}, {key: '专业技术岗位', label: '专业技术岗位'}, {key: '比照专业技术岗位', label: '比照专业技术岗位'}, {key: '工勤技能岗位', label: '工勤技能岗位'}],
    jobTitleWorkers: 'all'
  },
  orgName: '桐城市妇幼保健计划生育服务中心',
  orgNameDisabled: true,
  needOrgName2: true,
  orgNameLabel: '机构名称1',
  orgName2: '桐城市妇幼保健院',
  orgName2Disabled: true
}
