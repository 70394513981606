var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%","position":"relative"}},[_c('div',{staticClass:"img-doc-print"},[_c('div',{staticClass:"left"},[_vm._t("left")],2),_c('div',{ref:"content",staticClass:"doc-content"},_vm._l((_vm.pages),function(page,i){
var _obj;
return _c('div',{key:i,staticClass:"page",class:( _obj = {}, _obj[page.direction] = true, _obj.active = _vm.cursor.page === i, _obj ),attrs:{"data-page-index":i},on:{"click":function($event){$event.stopPropagation();return _vm.togglePage(i)}}},[_c('div',{staticClass:"imgs"},_vm._l((page.imgs),function(img,l){
      var _obj;
return _c('div',{key:l + '-' + img.src,staticClass:"img",class:{
              active: _vm.cursor.page === i && _vm.cursor.index === l
            },style:(( _obj = {}, _obj[page.direction === 'row' ? 'align-items' : 'justify-content'] = img.align, _obj )),attrs:{"data-page-img-index":i + '-' + l}},[_c('img',{attrs:{"src":img.src},on:{"click":function($event){$event.stopPropagation();return _vm.toggleImg(i, l)}}})])}),0)])}),0),_c('div',{staticClass:"right"},[_c('div',{staticClass:"plane"},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"title"},[_vm._m(0),_c('i',{staticClass:"iconfont icon-qidong action-icon",on:{"click":_vm.quit}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.status.page),expression:"status.page"}],staticClass:"block"},[_c('div',{staticClass:"title"},[_vm._m(1),_c('i',{staticClass:"iconfont icon-close action-icon",staticStyle:{"font-size":"12px","font-weight":"400","color":"#657180"},on:{"click":function($event){_vm.status.page = false}}})]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"block-icon-actions"},[_vm._l((_vm.pageMenus),function(menu){
            var _obj;
return [(menu.split)?_c('span',{key:menu.key + 'span',staticStyle:{"color":"rgb(190,190,190)"}},[_vm._v("|")]):_vm._e(),_c('fm-poptip',{key:menu.key + 'poptip',attrs:{"arrow":"","trigger":"hover"}},[_c('div',[_c('i',{staticClass:"img-action-icon",class:( _obj = {}, _obj[menu.icon] = true, _obj ),on:{"click":function($event){return _vm.pageAction(menu)}}}),(menu.label)?_c('span',[_vm._v(_vm._s(menu.label))]):_vm._e()]),_c('div',{staticStyle:{"white-space":"nowrap","padding":"5px"},attrs:{"slot":"content"},slot:"content"},[_vm._v(_vm._s(menu.desc))])])]})],2)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.status.img),expression:"status.img"}],staticClass:"block"},[_c('div',{staticClass:"title"},[_vm._m(2),_c('i',{staticClass:"iconfont icon-close action-icon",staticStyle:{"font-size":"12px","font-weight":"400","color":"#657180"},on:{"click":function($event){_vm.status.img = false}}})]),_c('div',{staticClass:"content"},[_c('div',{directives:[{name:"loadingx",rawName:"v-loadingx",value:(_vm.loading.imgAction),expression:"loading.imgAction"}],staticClass:"block-icon-actions"},[_vm._l((_vm.imgMenus),function(menu){
            var _obj;
return [(menu.split)?_c('span',{key:menu.key + 'span',staticStyle:{"color":"rgb(190,190,190)"}},[_vm._v("|")]):_vm._e(),_c('fm-poptip',{key:menu.key + 'poptip',attrs:{"arrow":"","trigger":"hover"}},[_c('div',[_c('i',{staticClass:"img-action-icon",class:( _obj = {}, _obj[menu.icon] = true, _obj ),on:{"click":function($event){return _vm.imgAction(menu)}}}),(menu.label)?_c('span',[_vm._v(_vm._s(menu.label))]):_vm._e()]),_c('div',{staticStyle:{"white-space":"nowrap","padding":"5px"},attrs:{"slot":"content"},slot:"content"},[_vm._v(_vm._s(menu.desc))])])]})],2)])]),_c('div',{staticClass:"block tap",on:{"click":_vm.addPage}},[_vm._m(3)]),_c('div',{staticClass:"block tap",on:{"click":_vm.print}},[_vm._m(4)])])])]),_c('fm-modal',{attrs:{"width":"1000px"},model:{value:(_vm.status.choose),callback:function ($$v) {_vm.$set(_vm.status, "choose", $$v)},expression:"status.choose"}},[_c('div',{staticClass:"choose-list"},_vm._l((_vm.sources),function(item,i){return _c('div',{key:i + '-' + item.src,staticClass:"choose-img"},[_c('img',{attrs:{"src":item.src},on:{"click":function($event){return _vm.chooseImg(item)}}})])}),0)]),_c('div',{ref:"cm2px",staticStyle:{"display":"none","padding":"2cm 1.2cm","width":"21.0529166667cm","height":"29.7529166667cm"}}),_c('iframe',{ref:"iframe",staticStyle:{"display":"none"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"name"},[_c('i',{staticClass:"iconfont icon-pages"}),_c('span',{staticStyle:{"font-size":"16px","font-weight":"800"}},[_vm._v("图片打印预览编辑器")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"name"},[_c('i',{staticClass:"iconfont icon-break-page"}),_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("页面设置")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"name"},[_c('i',{staticClass:"iconfont icon-img-add"}),_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("图片设置")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"name"},[_c('i',{staticClass:"iconfont icon-add"}),_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("添加一页")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"name"},[_c('i',{staticClass:"iconfont icon-printer"}),_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("打印")])])])}]

export { render, staticRenderFns }