<style scoped lang="less">
  table {
    border-left: none !important;
  }
  thead {
    background-color: #FAFAFA;
  }
  th, td {
    padding: 0 20px !important;
    text-align: left;
    font-size: 12px;
    line-height: 1;
    border-right: 1px solid #dcdee2;
    &:last-of-type {
      border-right: none;
    }
  }
  tbody tr:nth-of-type(even) {
    background-color: #FAFAFA;
  }
  tr:last-of-type td {
    border-bottom: none !important;
  }
</style>

<style lang="less">
  .update-details-table {
    .fm-table-expand-cell {
      padding: 0 10px;
    }
  }
</style>

<template>
  <fm-table-new
    :columns="columnList"
    border="row"
    ref="table"
    :data-list="content"
    :stripe="false"
    size="small"
    emptyPlaceholder="-">
    <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
  </fm-table-new>
</template>

<script>
import TableActions from '../TableActions'
export default {
  components: {
    TableActions
  },
  props: {
    content: {
      type: Array, default () {
        return []
      }
    },
    applyType: {
      type: String
    }
  },
  methods: {
    tableAction (parm) {
      console.log('tableAction', parm)
      this.$emit('checkField', {
        status: parm.action,
        key: parm.data.applyKey
      })
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'pass',
        label: '通过',
        show: (data) => {
          return this.applyType === 'edit' && data.applyKey && true
        }
      },
      {
        key: 'down',
        label: '驳回',
        show: (data) => {
          return this.applyType === 'edit' && data.applyKey && true
        }
      }]
    },
    columnList () {
      let data = [{
        title: '内容',
        field: 'title'
      },
      {
        title: '当前值',
        field: 'current',
        render: (h, rowData) => {
          return rowData.render ? rowData.render(h, rowData.current) : h('div', rowData.current)
        }
      },
      {
        title: '提交值',
        field: 'updateText',
        render: (h, rowData) => {
          return rowData.render ? rowData.render(h, rowData.updateText) : h('div', rowData.updateText)
        }
      },
      {
        title: '操作',
        slot: 'actions',
        fixed: 'right',
        search: false,
        export: false,
        configurable: false
      }]
      return this.applyType === 'edit' ? data : data.slice(0, data.length - 1)
    }
  }
}
</script>
