
const generateBasisUrl = function (url) {
  return Object.freeze({
    basUrl: url,
    parmUrl: (id) => url + `/${id}`,
  })
}

export const auth = Object.freeze({
  login: '/ucenter/oauth/token',
  getPubKey: '/ucenter/oauth/pre/token',
  getInfo: '/ucenter/user/current',
  logOut: '/ucenter/oauth/token',
  updatePwd: '/ucenter/user/myPassword'
})

export const menuApis = Object.freeze({
  parmUrl: (id) => `/ucenter/system/menus/${id}/apis`
})

export const userRole = Object.freeze({
  parmUrl: (userId) => `/ucenter/user/${userId}/roles`
})

export const resetPassword = Object.freeze({
  parmUrl: (userId) => `/ucenter/user/${userId}/password/default`
})

export const systemApis = Object.freeze({
  url: '/ucenter/system/apis'
})

export const refQuery = Object.freeze({
  get: (key) => `/basis/data/ref/query/${key}`
})

export const user = generateBasisUrl('/ucenter/user')

export const role = generateBasisUrl('/ucenter/system/roles')

export const fun = Object.assign({}, generateBasisUrl('/ucenter/system/funs'), {
  updateApi: (id) => `/ucenter/system/funs/${id}/apis`
})

export const menu = generateBasisUrl('/ucenter/system/menus')

export const data = Object.freeze({
  get: (name) => `/basis/data/${name}`,
  add: (name) => `/basis/data/${name}`,
  update: (name, id) => `/basis/data/${name}/${id}`,
  del: (name, id) => `/basis/data/${name}/${id}`,
})

export const common = Object.freeze({
  get: (name) => `/basis/${name}`,
  add: (name) => `/basis/${name}`,
  update: (name, id) => `/basis/${name}/${id}`,
  del: (name, id) => `/basis/${name}/${id}`,
})

export const file = Object.freeze({
  mkdir: '/fms/file/mkdir',
  upload: '/fms/upload',
  download: '/fms/download',
  del: '/fms/file'
})


export const ucenter = Object.freeze({
  authorities: (id) => `/ucenter/user/${id}/authorities`,
  users: '/ucenter/user'
})

export const operationLog = Object.freeze({
  get: '/basis/operation_log'
})

export const dataAuditLog = Object.freeze({
  base: '/basis/data/audit/log'
})

export const workOperation = Object.freeze({
  log: '/basis/operation/log'
})

export const agreement = Object.freeze({
  warning: '/basis/agreement/warning'
})

export const hisOrder = Object.freeze({
  get: '/basis/hisOrder'
})

export const org = Object.freeze({
  getTree: '/basis/org/tree',
  getAllTree: '/basis/org/all_tree',
  getAll: '/basis/org/all'
})

export const position = Object.freeze({
  getBaseCount: '/basis/position/base_count'
})

export const salary = Object.freeze({
  calculate: '/basis/salary/calculate',
  get_worker_salary: '/basis/data/ref/query/get_worker_salary',
  volume: '/basis/salary/worker/month/volume',
  init: '/basis/salary/init/org',
  initWorker: '/basis/salary/init/worker',
  updateAvg: '/basis/salary/add/avg',
  addOrg: '/basis/salary/add/org',
  addWorker: '/basis/salary/add/worker',
  count: 'basis/salary/count'
})

export const dataSyncLog = Object.freeze({
  getLastLog: '/basis/data_sync_log/lastLog',
  syncData: '/basis/data_sync_log/sync'
})

export const jobTitle = Object.freeze({
  getQueue: '/basis/worker_job_title/queue',
  getCurrent: '/basis/worker_job_title/current'
})

export const worker = Object.freeze({
  getDetail: '/basis/worker/detail',
})

export const dataerImport = Object.freeze({
  url: '/basis/import'
})

export const wage = Object.freeze({
  clear: '/basis/wage/clear'
})
